import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import React from 'react'

import FeatureCardVertical from '../UI/FeatureCardVertical'
import Wrapper from '../UI/Wrapper'
import { IndustrySegmentsSection } from './styles'

interface IndustrySegmentsProps {
  content: any
}

const IndustrySegments = (props: IndustrySegmentsProps) => {
  const { content } = props

  return (
    <IndustrySegmentsSection>
      <Wrapper>
        <Typography className="headline" component="h2" variant="h3">
          {content?.headline}
        </Typography>
        <Grid className="cardGrid" container>
          {content?.industries?.map((industry: any) => (
            <FeatureCardVertical
              body={industry.industry?.[0]?.subheading}
              cardType=""
              featureImage={industry.industry?.[0]?.feature_image}
              headline={industry.industry?.[0]?.headline}
              key={industry.id}
              linkTarget={industry.url}
            />
          ))}
        </Grid>
      </Wrapper>
    </IndustrySegmentsSection>
  )
}

export default IndustrySegments
