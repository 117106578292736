import { graphql, HeadProps, PageProps } from 'gatsby'
import React from 'react'

import ClientLogos from '../components/ClientLogos'
import Hero from '../components/Hero'
import ImpactModule from '../components/Impact'
import IndustrySegments from '../components/IndustrySegments'
import Layout from '../components/Layout'
import PageBody from '../components/PageBody'
import RelatedResources from '../components/RelatedResources'
import RelatedSolutions from '../components/RelatedSolutions'
import { SchemaMarkup } from '../components/SchemaMarkup/SchemaMarkup'
import { SchemaMarkupBreadcrumb } from '../components/SchemaMarkup/SchemaMarkupBreadcrumb'
import StepForm from '../components/StepForm'
import createDownloadObject from '../utils/createDownloadObject'

const IndustrySpecificPage = (
  props: PageProps<Queries.IndustrySpecificPageQuery>,
) => {
  const pageData = props?.data?.contentstackPageIndustry
  const resourceDownloadUrl = props?.data?.downloadResource?.url

  const parentPage = props?.data?.parentPage?.nodes?.[0]
  const grandParentPage = props?.data?.grandParentPage

  const breadcrumbLinks = [{ text: pageData?.title || '', target: '' }]

  if (grandParentPage?.title && grandParentPage?.url) {
    breadcrumbLinks.unshift({
      text: grandParentPage?.title,
      target: grandParentPage?.url,
    })

    // is there a category specified on the page
    if (
      pageData?.industry?.length > 0 &&
      pageData?.industry?.[0]?.category?.length > 0
    ) {
      // find the link to the category
      const linkReferences =
        props?.data?.allContentstackTopicLink?.edges.filter((edge) => {
          if (
            edge?.node?.internal_page.length > 0 &&
            edge?.node?.internal_page[0].title !== undefined
          ) {
            return (
              edge?.node?.internal_page[0].title.indexOf(
                pageData?.industry[0].category[0]?.title,
              ) !== -1
            )
          }
          return false
        })

      if (linkReferences.length > 0) {
        breadcrumbLinks.splice(1, 0, {
          text: pageData?.industry[0].category[0]?.title,
          target: linkReferences[0].node.internal_page[0].url,
        })
      }
    }
  } else if (parentPage?.title && parentPage?.url) {
    breadcrumbLinks.unshift({
      text: parentPage?.title,
      target: parentPage?.url,
    })
  }

  let resourcesByFilter
  switch (props.pageContext.filterType) {
    case 'Contentstack_topic_resource_type':
      resourcesByFilter = [
        ...props?.data?.resourceCardsByResourceTypeFilter?.nodes,
      ]
      break
    case 'Contentstack_topic_industry':
      resourcesByFilter = [...props?.data?.resourceCardsByIndustryFilter?.nodes]
      break
    case 'Contentstack_topic_role':
      resourcesByFilter = [...props?.data?.resourceCardsByRoleFilter?.nodes]
      break
    default:
      resourcesByFilter = []
      break
  }

  const allFeaturedResourceCards =
    props?.data?.allFeaturedResourceCards?.nodes || []
  if (resourcesByFilter.length === 0) {
    allFeaturedResourceCards?.forEach((resource: any) =>
      resourcesByFilter.push(resource),
    )
  }

  const metaTitle =
    pageData?.metadata?.page_title ||
    pageData?.title ||
    pageData?.page_header?.[0]?.headline ||
    pageData?.page_header?.[0]?.topic?.[0]?.headline
  const metaDesc =
    pageData?.metadata?.description ||
    pageData?.page_header?.[0]?.subheading ||
    pageData?.page_header?.[0]?.topic?.[0]?.subheading
  const metaImg =
    pageData?.metadata?.image ||
    pageData?.page_header?.[0]?.feature_image ||
    pageData?.page_header?.[0]?.topic?.[0]?.feature_image

  const downloadResourceObject = createDownloadObject(props, 'props')

  return (
    <Layout
      breadcrumbLinks={breadcrumbLinks}
      description={metaDesc}
      image={metaImg}
      locale={pageData?.publish_details?.locale}
      slug={props.pageContext.slug}
      title={metaTitle}
    >
      <Hero content={pageData?.page_header?.[0]} />

      {pageData?.client_logos?.[0] ? (
        <ClientLogos
          content={pageData?.client_logos[0]}
          noBottomPadding={!pageData?.industry_segments?.[0]}
        />
      ) : null}

      {pageData?.industry_segments?.[0] ? (
        <IndustrySegments content={pageData?.industry_segments?.[0]} />
      ) : null}

      {pageData?.page_body?.[0] ? (
        <PageBody
          content={pageData?.page_body?.[0]}
          downloadResource={downloadResourceObject}
          downloadUrl={resourceDownloadUrl}
          noTopPadding={!pageData?.industry_segments?.[0]}
        />
      ) : null}

      {pageData?.featured_programs?.[0] ? (
        <RelatedSolutions relatedSolutions={pageData?.featured_programs?.[0]} />
      ) : null}

      {pageData?.impact?.[0] ? (
        <ImpactModule content={pageData?.impact?.[0]} />
      ) : null}

      {pageData?.contact_us_form?.[0]?.variant === 'Step' && (
        <StepForm formData={pageData?.contact_us_form?.[0]} />
      )}

      {pageData?.related_resources?.[0] ? (
        <RelatedResources
          content={{
            related_resources: resourcesByFilter || [],
            ...pageData?.related_resources?.[0],
          }}
        />
      ) : null}
    </Layout>
  )
}

export const Head = ({
  data,
}: HeadProps<Queries.IndustrySpecificPageQuery>) => (
  <>
    <SchemaMarkup data={data.contentstackPageIndustry?.schema_markup} />
    <SchemaMarkupBreadcrumb
      grandParent={data?.grandParentPage}
      pageTitle={data?.contentstackPageIndustry?.title}
      parent={
        data?.parentPage?.nodes?.[0]
          ? {
              title: data?.parentPage?.nodes?.[0]?.title,
              pageUrl: data?.parentPage?.nodes?.[0]?.url,
            }
          : null
      }
    />
  </>
)

export default IndustrySpecificPage

export const data = graphql`
  query IndustrySpecificPage(
    $pageId: String
    $locale: String
    $downloadResourceId: String
    $filterId: String
    $downloadId: String
    $parentSlug: String
    $grandParentSlug: String
  ) {
    contentstackPageIndustry(id: { eq: $pageId }) {
      id
      title
      locale
      chinese_version_url
      publish_details {
        locale
      }
      metadata {
        page_title
        description
        image {
          description
          url
        }
      }
      schema_markup {
        ...SchemaMarkup
      }
      page_header {
        ...PageHeaderSection
      }
      client_logos {
        ...ClientLogos
      }
      industry {
        ...IndustrySection
      }
      industry_segments {
        ...IndustrySegmentsSection
      }
      page_body {
        ...PageBodySection
      }
      featured_programs {
        ...FeaturedProgramsSection
      }
      impact {
        ...ImpactStatsSection
      }
      contact_us_form {
        ...LeadGenForm
      }
      related_resources {
        ...RelatedResourcesSection
      }
    }

    downloadResourceUrl: allContentstackPageResource(
      filter: {
        publish_details: { locale: { eq: $locale } }
        resource: { elemMatch: { id: { eq: $downloadResourceId } } }
      }
    ) {
      nodes {
        pageUrl: url
        resource {
          id
        }
      }
    }

    resourceCardsByRoleFilter: allContentstackPageResource(
      filter: {
        publish_details: { locale: { eq: $locale } }
        resource: {
          elemMatch: { roles: { elemMatch: { id: { eq: $filterId } } } }
        }
      }
      limit: 15
    ) {
      nodes {
        id
        title
        url
        resource {
          ...ResourceCardFragment
        }
      }
    }

    resourceCardsByResourceTypeFilter: allContentstackPageResource(
      filter: {
        publish_details: { locale: { eq: $locale } }
        resource: {
          elemMatch: { resource_type: { elemMatch: { id: { eq: $filterId } } } }
        }
      }
      limit: 15
    ) {
      nodes {
        id
        title
        url
        resource {
          ...ResourceCardFragment
        }
      }
    }

    resourceCardsByIndustryFilter: allContentstackPageResource(
      filter: {
        publish_details: { locale: { eq: $locale } }
        resource: {
          elemMatch: { industry: { elemMatch: { id: { eq: $filterId } } } }
        }
      }
      limit: 15
    ) {
      nodes {
        id
        title
        url
        resource {
          ...ResourceCardFragment
        }
      }
    }

    allFeaturedResourceCards: allContentstackPageResource(
      filter: {
        publish_details: { locale: { eq: $locale } }
        resource: { elemMatch: { is_featured: { eq: "Yes" } } }
      }
      sort: { fields: resource___publication_date, order: DESC }
      limit: 15
    ) {
      nodes {
        url
        resource {
          ...ResourceCardFragment
        }
      }
    }

    parentUrl: contentstackPageIndustryOverview(
      publish_details: { locale: { eq: $locale } }
    ) {
      title
      pageUrl: url
    }

    parentPage: allContentstackPageIndustry(
      filter: {
        url: { eq: $parentSlug }
        publish_details: { locale: { eq: $locale } }
      }
    ) {
      nodes {
        title
        url
        id
      }
    }

    grandParentPage: contentstackPageIndustryOverview(
      url: { eq: $grandParentSlug }
      publish_details: { locale: { eq: $locale } }
    ) {
      title
      url
      id
    }

    allContentstackTopicLink(
      filter: { publish_details: { locale: { eq: $locale } } }
    ) {
      edges {
        node {
          internal_page {
            ... on Contentstack_page_industry {
              id
              url
              title
              uid
              locale
            }
          }
        }
      }
    }

    downloadResourceCard: allContentstackPageResource(
      filter: {
        publish_details: { locale: { eq: $locale } }
        resource: {
          elemMatch: { is_gated: { eq: "Yes" }, id: { eq: $downloadId } }
        }
      }
    ) {
      nodes {
        title
        url
        resource {
          headline
          side_cta_short_description
          feature_image {
            description
            imgixImage {
              gatsbyImageData(width: 374, imgixParams: { q: 60 })
            }
          }
        }
      }
    }

    allContentstackResourcesWebsite(
      filter: { publish_details: { locale: { eq: $locale } } }
    ) {
      nodes {
        download_card_cta_text
      }
    }
  }
`
