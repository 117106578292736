import Typography from '@material-ui/core/Typography'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'

import Wrapper from '../UI/Wrapper'
import { LogoGrid, LogoSection } from './styles'

interface ClientLogosProps {
  content: any
  noBottomPadding?: boolean
}

interface FeatureLogoProps {
  client: {
    logo: {
      description: string
      url: string
      imgixImage: any
    }
  }
}

const FeatureLogo = (props: FeatureLogoProps) => {
  const { client } = props
  const image = getImage(client?.logo?.imgixImage)
  const altText = client?.logo?.description || ''
  const url = client?.logo?.url

  if (url?.endsWith('.svg')) {
    return (
      <li>
        <img alt={altText} src={url} />
      </li>
    )
  }

  return <li>{image ? <GatsbyImage alt={altText} image={image} /> : null}</li>
}

const ClientLogos = (props: ClientLogosProps) => {
  const { content, noBottomPadding } = props
  const rows = content?.rows || '1'
  const columns = content?.columns || '6'
  const maxNumberOfLogos = parseInt(rows) * parseInt(columns)
  const logosToDisplay =
    content?.clients?.length > maxNumberOfLogos
      ? content?.clients?.slice(0, maxNumberOfLogos)
      : content?.clients

  return (
    <LogoSection noBottomPadding={Boolean(noBottomPadding)}>
      <Wrapper>
        <Typography
          color="secondary"
          component="h2"
          style={{ marginBottom: '64px', textAlign: 'center' }}
          variant="h2"
        >
          {content?.headline}
        </Typography>
        {logosToDisplay?.length > 0 ? (
          <LogoGrid columns={columns} rows={rows}>
            {logosToDisplay?.map((client: any) => (
              <FeatureLogo client={client} key={client.id} />
            ))}
          </LogoGrid>
        ) : null}
      </Wrapper>
    </LogoSection>
  )
}

export default ClientLogos
