import styled from 'styled-components'

interface LogoSectonProps {
  noBottomPadding: boolean
}

export const LogoSection = styled.section<LogoSectonProps>`
  padding: 86px 0;
  padding-bottom: ${(props) => props.noBottomPadding && 0};

  @media (max-width: 768px) {
    padding: 48px 0 72px;
  }
`

interface LogoGridProps {
  rows: string
  columns: string
}

export const LogoGrid = styled.ul<LogoGridProps>`
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  gap: 36px;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  grid-template-rows: repeat(${(props) => props.rows}, 1fr);
  place-items: center;

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  }
`
