import styled from 'styled-components'

import { rspTheme } from '../../styles/rspTheme'

export const IndustrySegmentsSection = styled.section`
  padding: 94px 0 72px;
  background-color: ${rspTheme.palette.primary.background};

  .headline {
    margin-bottom: 64px;
    color: ${rspTheme.palette.primary.main};
  }

  .cardGrid {
    gap: 26px;
    justify-content: center;
  }

  @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
    padding: 48px 0;

    .headline {
      margin-bottom: 32px;
      font-size: 2.8rem;
    }
  }
`
